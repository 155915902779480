<template>
    <v-card class="backgroundColor" :height="$vuetify.breakpoint.mdAndUp ? `100%` : `100vmax`" >
      <div 
      class="Welcometitle primary--text titleFontlg">
        Modification de compte
      </div>
     <MyInfo />
    </v-card>
</template>

<script>
import MyInfo from "/src/components/MyAccountComponents/MyInfo.vue";

export default {
  data: () => ({}),
  components: {
    MyInfo,
  },
  methods: {},
};
</script>
  
<style scoped>

.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.titleFontlg {
  font-size: 28px;
}
</style>
