<template>
  <div class="ma-3">
    <!-- INFORMATIONS -->
    <div class="subtitle">Mon identité</div>
    <v-card
        class="mx-auto "
        max-width="700px"
        elevation="0"
    >
      <v-form>
        <v-row
            justify="center"
            class="pt-5"
        >
          <v-col
              cols="10"
              sm="4"
              class="pb-0"
          >
            <v-text-field
                v-model="this.$store.state.user.prenom"
                label="Prénom *"
                dense
                filled
                disabled
            />
          </v-col>
          <v-col
              cols="10"
              sm="4"
              class="pb-0"
          >
            <v-text-field
                v-model="this.$store.state.user.nom"
                label="Nom *"
                dense
                filled
                disabled
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- MDP -->
    <div class="subtitle pt-5 ">Mot de passe</div>
    <v-card
        class="mx-auto pb-5"
        width="700px"
        elevation="0"
    >
      <v-form
          ref="form"
          v-model="valid"
      >
        <v-row
            justify="center"
            class="pt-5"
        >
          <v-col
              cols="10"
              sm="4"
              class="pb-0"
          >
            <v-text-field
                v-model="user.password"
                :rules="pwRules"
                label="Nouveau mot de passe"
                type="password"
                required
                dense
                filled
            />
          </v-col>
          <v-col
              cols="10"
              sm="4"
              class="pb-0"
          >
            <v-text-field
                v-model="user.passwordConfirmation"
                :rules="[
                user.password === user.passwordConfirmation ||
                  'Le mot de passe doit correspondre',
              ]"
                label="Confirmation"
                type="password"
                required
                dense
                filled
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card
        class="mx-auto h1 backgroundColor"
        width="700px"
        elevation="0"
    >
      <!-- TODO remove line 99 -->
      <!-- <p class="font-italic secondary--text pb-6">* Champs obligatoires</p> -->
      <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'end'"
          class="pr-3 py-6"
      >
        <NextButton
            width="auto"
            :loading="loading"
            color="#fb4d61"
            text="modifier mon mot de passe"
            @clicked="sendAcountModif()"
        />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import NextButton from "/src/components/Utility/NextButton";
import UserService from "/src/services/UserService";

export default {
  components: {
    NextButton,
  },
  data: () => ({
    user: {
      name: "",
      prenom: "",
      valid: false,
      password: "",
      passwordConfirmation: "",
    },
    loading: false,
    ERROR_MESSAGE: `Mot de passe invalide :
veuillez utiliser au moins 8 caractères dont une majuscule, une minuscule, un 
chiffre et un caractère spécial.`,
    valid: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
          this.user.password === this.user.passwordConfirmation ||
          "Les mots de passe doivent être identiques"; //"Password must match";
    },
    pwRules() {
      return [
        (v) => !!v || "Mot de passe requis",
        (v) => (v && v.length >= 8 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()=+{}_#?!@$%^&*-]).{8,}$/.test(v)) || this.ERROR_MESSAGE, // Password must have 8+ characters
      ];
    },
  },
  methods: {
    sendAcountModif() {
      //Valide Formulaire
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        UserService.putAccount(this.user.passwordConfirmation).catch(() => {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue lors de la modification de votre mot de passe",
          });
        }).then(() => {
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Votre mot de passe a bien été modifié !",
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    sendRefreshReq() {
      this.$store.dispatch("auth/refresh_token");
    },
  },
};
</script>
<style scoped>
.subtitle {
  text-align: center;
  margin-bottom: 16px;
  color: #034155;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
}
</style>
